// BuildInfo.tsx
import React, { useEffect, useState } from 'react';

interface BuildInfoType {
  buildMajor: number;
  buildMinor: number;
  buildRevision: number;
  buildTime: string;
}

export const BuildInfo: React.FC = () => {
  const [buildInfo, setBuildInfo] = useState<BuildInfoType | null>(null);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  useEffect(() => {
    fetch('/build-info.json')
      .then((response) => response.json())
      .then((data: BuildInfoType) => setBuildInfo(data))
      .catch((error) => console.error('Error loading build info:', error));
  }, []);

  const handleClick = () => {
    setIsVisible(false);
  };

  const buildInfoStyle = {
    position: 'fixed' as const,
    bottom: '10px',
    right: '10px',
    background: '#f0f0f0',
    padding: '10px',
    borderTop: '1px solid #ccc',
    fontSize: '12px',
    textAlign: 'center' as const,
    zIndex: 1000,
    opacity: isHovered ? 0.5 : 1,
    transition: 'opacity 0.3s ease',
    cursor: 'pointer',
  };

  if (process.env.REACT_APP_ENV === 'production' || !buildInfo || !isVisible) {
    return null;
  }

  return (
    <div
      style={buildInfoStyle}
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      Build v{buildInfo.buildMajor}.{buildInfo.buildMinor}.
      {buildInfo.buildRevision} -{' '}
      {new Date(buildInfo.buildTime).toLocaleString()}
    </div>
  );
};