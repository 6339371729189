import { Link, useLocation } from '@remix-run/react';
import FacebookLogo from '~/assets/svgs/facebookLogo';
import LinkedInLogo from '~/assets/svgs/linkedIn';
import XLogo from '../assets/twitter_x.png';
import '../styles/footer.css';
import { BuildInfo } from './BuildInfo/BuildInfo';
import { useEffect, useState } from 'react';

export const Footer = () => {
  const location = useLocation();
  const [textColor, setTextColor] = useState('var(--text-color, #000)');

  // Get the current year for copyright
  const startYear = 2024;
  const currentYear = new Date().getFullYear();
  const yearDisplay = currentYear > startYear
    ? `${startYear}-${currentYear}`
    : startYear;

  // Use a color detection approach
  useEffect(() => {
    const detectBackgroundColor = () => {
      // Get the computed background color of the footer
      const footer = document.querySelector('footer');
      if (footer) {
        const bgColor = window.getComputedStyle(footer).backgroundColor;

        // Parse the RGB values
        const rgb = bgColor.match(/\d+/g);
        if (rgb && rgb.length >= 3) {
          // Calculate brightness using the formula (0.299*R + 0.587*G + 0.114*B)
          const brightness = (0.299 * parseInt(rgb[0]) +
            0.587 * parseInt(rgb[1]) +
            0.114 * parseInt(rgb[2])) / 255;

          // If brightness is greater than 0.5, background is light, use dark text
          setTextColor(brightness > 0.5 ? '#000' : '#fff');
        }
      }
    };

    detectBackgroundColor();

    // Add a resize listener to recalculate if the layout changes
    window.addEventListener('resize', detectBackgroundColor);

    return () => {
      window.removeEventListener('resize', detectBackgroundColor);
    };
  }, [location.pathname]);

  return (
    <footer
      className={`${location.pathname === '/uploads/projects' && 'uploadsFooter'}`}
    >
      <div className="rowArrangement footer">
        <div id="logoFooter">
          <p id="logoText" style={{ color: textColor }}>
            &copy; BLUESKEYE AI {yearDisplay} | All Rights Reserved
          </p>
        </div>
        <div id="footerButtonGroup">
          <Link to={`https://uk.linkedin.com/company/blueskeye-ai`}>
            <LinkedInLogo fill={textColor} />
          </Link>
          <Link to={`https://x.com/blueskeyeai`}>
            <img
              src={XLogo}
              alt="X Logo"
              style={{
                width: '38px',
                filter: textColor === '#fff' ? 'invert(1)' : 'none'
              }}
              onError={(e) => {
                e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
              }}
            />
          </Link>
          <Link to={`https://www.facebook.com/BlueskeyeAI`}>
            <FacebookLogo fill={textColor} />
          </Link>
        </div>
      </div>
      {/* BuildInfo component with its original styling */}
      <BuildInfo />
    </footer>
  );
};